
import RealEstateListingsListCard from '@/components/real-estate/RealEstateListingsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-real-estate-listings',
  components: { RealEstateListingsListCard },
})
export default class ProjectRealEstateListings extends mixins(ProjectViewMixin) {}
